import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

export default async function fetchData() {
  const gameTag = CONTENTFUL_TAGS[GAME_SHORT_NAMES[GAME_SYMBOL_VAL]];
  await Promise.all([
    getEntriesByTags([gameTag, CONTENTFUL_TAGS.TILES_GRID], 4),
    getEntriesByTags([gameTag, CONTENTFUL_TAGS.TILES_LIST], 4),
  ]);
}
